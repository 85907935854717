import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

const Container = styled.div`
  height: calc(100vh - 250px);
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: lightgrey;
`;

export default () => (
  <Container>
    <h1>404</h1>
    <h2>Þessi síða fannst ekki</h2>
    <br />
    <Link to="/">Aftur á forsíðu</Link>
  </Container>
);
